import React from "react";
import "../assets/App.css";
import im from "../assets/_img_e_1.svg";
import im_s from "../assets/_img_e_1_s.jpg";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}

const Contact = () => {
    return (
        <AnimatePresence>
            <div className="content_main">

                <motion.div
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={im_s}
                        style={{
                            maxWidth: "85%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none"
                        }}
                        src={im}
                        alt="background line drawing"
                        effect="opacity" />
                </motion.div>

                <motion.div className="inner"
                    id="contact"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <div className="_i">
                        <h1>Events</h1>
                        <br /><br />

                        <ul className="events"><span className="events_item">November 2</span>
                            <li>AfterWords Literary Festival - Halifax, NS</li>
                        </ul>

                        <ul className="events"><span className="events_item">November 21-24</span>
                            <li>NCTE Annual Convention - Boston, MA</li>
                        </ul>


                    </div>
                </motion.div>

            </div >
        </AnimatePresence >
    );
}
export default Contact;