import React from "react";
import "../assets/App.css";
import im from "../assets/_img_abt_1.svg";
import im_s from "../assets/_img_abt_1_s.jpg";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const variants = {
    hidden_up: {
        opacity: 0,
        zIndex: 0,
        y: 50
    },
    hidden_down: {
        opacity: 0,
        zIndex: 0,
        y: -50
    },
    center: {
        y: 0,
        zIndex: 1,
        opacity: 1
    }
}


const Author = () => {
    return (
        <AnimatePresence>
            <div className="content_main">
                <motion.div
                    initial="hidden_down"
                    animate="center"
                    exit="hidden_up"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <LazyLoadImage className="inner_img"
                        placeholderSrc={im_s}
                        style={{
                            maxWidth: "85%",
                            userSelect: "none",
                            WebkitUserSelect: "none",
                            MozUserSelect: "none",
                            pointerEvents: "none"
                        }}
                        src={im}
                        alt="background line drawing"
                        effect="opacity" />
                </motion.div>

                <motion.div className="inner"
                    id="about"
                    initial="hidden_up"
                    animate="center"
                    exit="hidden_down"
                    variants={variants}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <div className="_i">
                        <h1>About</h1>
                        <p className="_fill">Sarah Everett is the author of several books for teens. Charlotte’s Web was the first book that ever made her cry, and while she despises spiders, she still has an abiding love of stories that move her. When she is not reading or writing, she is dreaming about summer, gearing up for her next travel adventure, perfecting her tree pose, or yodeling with her dog. She lives in western Canada. </p>
                    </div>
                </motion.div>
            </div>
        </AnimatePresence >
    );
}
export default Author;