import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Content from "./Content";
import Home from "../pages/Main";
import Author from "../pages/About";
import About from "../pages/About";
import Books from "../pages/Books";
import Media from "../pages/Media";
import Contact from "../pages/Contact";
import Events from "../pages/Events";
import ErrorPage from "../pages/ErrorPage";
import EWB from "../pages/Everyone-Weve-Been";
import HTLWY from "../pages/How-To-Live";
import NOHIL from "../pages/No-One-Here";
import TPOE from "../pages/The-Probability";
import TSOLT from "../pages/The-Shape";
import SON from "../pages/Some-Other-Now";
import "../assets/App.css";
import { AnimatePresence } from "framer-motion";
import ReactGA from "react-ga4";

const Layout = () => {

    return (
        <>
            <div className="outer">
                <div className="container">
                    <header>
                        <Header />
                    </header>
                    <main>
                        <Content />
                    </main>
                    <footer>
                        <Footer />
                    </footer >
                </div>
            </div>
        </>
    );
}


const MainContainer = () => {
    const location = useLocation();

    ReactGA.initialize('G-ET1H88NER6');

    //ReactGA.pageview(window.location.pathname);
    useEffect(() => {
        window.gtag(
            "event", "pageview", {
            path: location.pathname + location.hash + location.search,
            search: location.search,
            hash: location.hash,
        }, [location])
    });

    return (
        <>

            <AnimatePresence mode="wait" initial={false} layout layoutRoot>
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Layout />} >
                        <Route index element={<Home />} />
                        <Route path="pages/*" element={<Author />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/media" element={<Media />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/books" element={<Books />} />
                        <Route path="/ewb" element={<EWB />} />
                        <Route path="/htl" element={<HTLWY />} />
                        <Route path="/noh" element={<NOHIL />} />
                        <Route path="/tpoe" element={<TPOE />} />
                        <Route path="/tsolt" element={<TSOLT />} />
                        <Route path="/son" element={<SON />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Route>
                </Routes>
            </AnimatePresence>
        </>
    );
}
export default MainContainer;